import { redirectInvalidPinCode, redirectValidPinCode } from './app/utilities/pin-code-helper';
import { Route } from './app/utilities/route';

const routes: Route[] = [
  new Route({
    before: redirectInvalidPinCode,
    icon: 'home',
    label: 'Home',
    path: '/',
    promise: () => import('./app/pages/home/home'),
    render: true,
    tag: 'home-element'
  }),
  new Route({
    before: redirectValidPinCode,
    children: [
      new Route({
        before: redirectValidPinCode,
        label: 'Pin Code',
        path: '/:pin',
        promise: () => new Promise(() => { return; }),
        render: false,
        tag: ''
      })
    ],
    label: 'Pin Code',
    path: '/pin-code',
    promise: () => import('./app/pages/pin-code/pin-code'),
    render: false,
    tag: 'pin-code-element'
  }),
  new Route({
    label: 'Not Found',
    path: '*',
    promise: () => import('./app/pages/not-found/not-found'),
    render: false,
    tag: 'not-found-element'
  })
];

// todo, support multiple paths for one route
const getRoutes = (pages: Route[]): Route[] => {
  return pages.reduce((prev, curr) => {
    if (prev.some((i) => i.tag === curr.tag)) {
      return prev;
    }

    prev.push(curr);
    if (curr.children.length > 0) {
      prev.push(...curr.children.filter((i) => i.tag));
    }

    return prev;
  }, [] as Route[]);
};

export {
  routes,
  getRoutes
};
