import './app/app';
import { Router } from './app/utilities/router';
import { routes } from './bootstrap.routes';
import './bootstrap.scss';

(async () => {
  if ('serviceWorker' in navigator) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing) { return; }

        refreshing = true;
        window.location.reload();
      }
    );

    // navigator.serviceWorker.register('service-worker.js');
  }

  Router.init(routes);
})();
