import { html } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { getRoutes, routes } from '../bootstrap.routes';
import './app.scss';
import './components/footer/footer';
import './components/header/header';
import { BaseElement } from './utilities/base-element';
import { Route } from './utilities/route';
import { Router } from './utilities/router';

const pages = getRoutes(routes);

// allow pages to control header sizing/styling
// maybe pages should require a slot for always rendering the header

class AppElement extends BaseElement {

  getTag(route: Route) {
    const tag = route.tag;
    const classes = ['page'];
    if (Router.currentLocation.pathname === route.path) {
      classes.push('active');
    }
    return unsafeHTML(`<${tag} class="${classes.join(' ')}"></${tag}>`);
  }

  firstUpdated() {
    this.setupObserver();
  }

  setupObserver() {
    const header = document.querySelector('header-element') as HTMLElement;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const appBar = document.querySelector('mwc-top-app-bar-fixed') as HTMLElement;

        if (entry.isIntersecting) {
          appBar.removeAttribute('app-scroll');
        } else {
          appBar.setAttribute('app-scroll', '');
        }
      }, {});
    });

    observer.observe(header);
  }

  renderTags() {
    return pages.map((route) => html`${this.getTag(route)}`);
  }

  render() {
    return html`
      <header-element></header-element>

      <router-outlet>
        ${this.renderTags()}
      </router-outlet>

      <footer-element></footer-element>
    `;
  }
}

customElements.define('app-element', AppElement);
