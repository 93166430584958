import { html } from 'lit-html';
import { BaseElement } from '../../utilities/base-element';
import styles from './footer.module.scss';

const tag = 'footer-element';

class FooterElement extends BaseElement {

  render() {
    return html`
      <footer class="${styles.footer}">
        <section location>
          <div content>
            <a href='#'>About</a>
          </div>
        </section>
      </footer>
    `;
  }
}

customElements.define(tag, FooterElement);

export {
  FooterElement,
  tag
};
