import { LitElement } from 'lit-element';

class BaseElement extends LitElement {

  createRenderRoot() {
    return this;
  }
}

export {
  BaseElement
};
