import { Route } from './route';

const KEY = 'pin-code';
const SESSION_KEY = 'pin-code-session';

function getPinCode() {
  const result = document.cookie.split(';').find((cookie: string) => cookie.startsWith(KEY)) || '';

  return result.replace(`${KEY}=`, '');
}

function setPinCode(value: string) {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 30);
  document.cookie = `${KEY}=${value}; expires=${now.toUTCString()}; Path=/; SameSite=Lax`;
}

function getSession() {
  return JSON.parse(localStorage.getItem(SESSION_KEY) || '{}');
}

function setSession(value: any) {
  localStorage.setItem(SESSION_KEY, JSON.stringify(value));
}

function redirectInvalidPinCode(route: Route, ctx: PageJS.Context, next: () => void) {
  const result = getPinCode();
  if (!result) {
    ctx.stopRender = true;
    ctx.redirect = '/pin-code';
    return;
  }

  ctx.stopRender = false;
  ctx.redirect = '';
}

function redirectValidPinCode(route: Route, ctx: PageJS.Context, next: () => void) {
  const pin = ctx.params.pin ? ctx.params.pin : getPinCode();
  if (pin && pin.length === 6) {
    setPinCode(pin);
    ctx.stopRender = true;
    ctx.redirect = '/';
    return;
  }

  ctx.stopRender = false;
  ctx.redirect = '';
}

export {
  getPinCode,
  setPinCode,
  getSession,
  setSession,
  redirectInvalidPinCode,
  redirectValidPinCode
};
